import React, { useContext } from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from 'react-grid-system';
import { HeroPatients } from 'components/hero';
import { appContext } from 'providers/appProvider';
import ExternalLink from '../codes/components/external-link';

// import page style
import './support-materials.scss';

const SupportMaterials = () => {
    const { appConfigs } = useContext(appContext);
	return (
		<Layout indication='patient' className='patient-support-materials'>
			<Seo pageTitle='Helpful Information' />

			<div className='body-content-container hero-section'>
				<div className='body-content'>
					<HeroPatients heroImgAlt='page hero' className='sub-page-hero'>
						<h1>Helpful Information</h1>
					</HeroPatients>
					<section className='spacer-bottom'>
						<Row>
							<Col xs={12}>
								<h2 className='primary-headline'>
									<span className='text-bold'>
										Download helpful information about OxyContin
									</span>
								</h2>
								<ul className='oxc-list'>
									<li><span>The <span className='text-bold'>OxyContin Medication Guide</span>
											, which gives you important safety information about
											OxyContin
										</span>
									</li>
							    <ExternalLink
												externalURL='https://dailymed.nlm.nih.gov/dailymed/fda/fdaDrugXsl.cfm?setid=bfdfe235-d717-4855-a3c8-a13d26dadede&type=display#section-17'
												messageTitle={appConfigs.externalLinkInfo.TITLE}
												messageBody={appConfigs.externalLinkInfo.BODY}
												classNameLink='external-link'
												classNameOverlay={
													appConfigs.externalLinkInfo.OVERLAY_CLASS_NAME
												}
											>
													View the Medication Guide
											</ExternalLink>
                                            <br/><br/>
                             		<li><span>The <span className='text-bold'>OxyContin Full Prescribing Information</span>
										</span>
									</li>
								
							    <ExternalLink
												externalURL='https://dailymed.nlm.nih.gov/dailymed/fda/fdaDrugXsl.cfm?setid=bfdfe235-d717-4855-a3c8-a13d26dadede&type=display'
												messageTitle={appConfigs.externalLinkInfo.TITLE}
												messageBody={appConfigs.externalLinkInfo.BODY}
												classNameLink='external-link'
												classNameOverlay={
													appConfigs.externalLinkInfo.OVERLAY_CLASS_NAME
												}
											>
													View the Full Prescribing Information
											</ExternalLink>
                                            </ul>
							</Col>
						</Row>
                        <br/>
                        <Row>
                            <Col xs={12}>
                            <h1>Risk Evaluation and Mitigation Strategy (REMS)</h1>
                            <h2 className='primary-headline'>
									<span className='text-bold'>
                                    OxyContin is subject to the REMS for opioid analgesics</span>
              </h2>
              <p>
                The goal of the REMS is to reduce serious adverse outcomes
                resulting from inappropriate prescribing, abuse, and misuse of
                opioid analgesics, while maintaining patient access to
                these medications. Adverse outcomes of concern include
                addiction, unintentional overdose, and death.
              </p>
              <ul className="oxc-list">
                <li>
                  <span>FDA has required a REMS for opioid analgesics</span>
                </li>
                <li>
                    <a className="external-link" href="https://www.purduepharma.com/healthcare-professionals/responsible-use-of-opioids/" target="_blank" rel="noreferrer">Purdue Pharma is a member of the REMS Program Companies</a>
                </li>
              </ul>
              <h2 className='primary-headline'>
									<span className='text-bold'>
                OxyContin should be prescribed only by healthcare professionals
                who are knowledgeable in the use of potent opioids for the
                management of chronic pain.</span>
              </h2>
              <p>
                Documentation and maintenance of careful prescribing and
                treatment records are essential, and should include:
              </p>
              <ul className="oxc-list">
                <li>
                  <span>Physician and patient agreement</span>
                </li>
                <li>
                  <span>Proper assessment of the patient</span>
                </li>
                <li>
                  <span>Proper prescribing practices</span>
                </li>
                <li>
                  <span>Periodic reevaluation of therapy</span>
                </li>
                <li>
                  <span>
                    Proper dispensing and correct storage and handling
                  </span>
                </li>
                <li>
                  <span>
                    Careful record keeping of prescribing information, including
                    quantity, frequency, and renewal requests, is strongly
                    advised
                  </span>
                </li>
                <li>
                  <span className="text-normal">
                    Providing patient and/or caregiver with the{' '}
                    <ExternalLink
												externalURL='https://dailymed.nlm.nih.gov/dailymed/fda/fdaDrugXsl.cfm?setid=bfdfe235-d717-4855-a3c8-a13d26dadede&type=display#section-17'
												messageTitle={appConfigs.externalLinkInfo.TITLE}
												messageBody={appConfigs.externalLinkInfo.BODY}
												classNameLink='external-link'
												classNameOverlay={
													appConfigs.externalLinkInfo.OVERLAY_CLASS_NAME
												}
											>
											Medication Guide
											</ExternalLink>
                  </span>
                </li>
                <li>
                  <span className="text-normal">
                    Reviewing the Opioid REMS{' '}
                    <ExternalLink
												externalURL='https://www.opioidanalgesicrems.com/patientCounselingGuide.html'
												messageTitle={appConfigs.externalLinkInfo.TITLE}
												messageBody={appConfigs.externalLinkInfo.BODY}
												classNameLink='external-link'
												classNameOverlay={
													appConfigs.externalLinkInfo.OVERLAY_CLASS_NAME
												}
											>
												  Patient Counseling Guide
											</ExternalLink>{' '}
                    with the patient and/or caregiver at the time of prescribing
                  </span>
                </li>
              </ul>
            <p>
            <span className="text-normal">
                  For more information, including REMS-compliant educational CME/CE programs on safe prescribing of REMS for opioid analgesics, visit{" "} 
                    <ExternalLink
												externalURL='https://www.opioidanalgesicrems.com/'
												messageTitle={appConfigs.externalLinkInfo.TITLE}
												messageBody={appConfigs.externalLinkInfo.BODY}
												classNameLink='external-link text-teal'
												classNameOverlay={
													appConfigs.externalLinkInfo.OVERLAY_CLASS_NAME
												}
											>
												 opioidanalgesicrems.com
											</ExternalLink>.{' '}
              
                  </span>
            </p>
                            </Col>
                        </Row>
					</section>
				</div>
			</div>
		</Layout>
	);
};

export default SupportMaterials;
